import Vue from "vue";
import Router from "vue-router";
import MainLayout from "@/layouts/index";
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "MainLayout",
      redirect: "/map",
      component: MainLayout,
      children: [
        {
          path: "/map",
          name: "MapLayout",
          component: () => import(/* webpackChunkName: "map" */ "@/layouts/Home")
        },
        {
          path: "/disclaimerLayout",
          name: "DisclaimerLayout",
          component: () => import(/* webpackChunkName: "disclaimer" */ "@/layouts/Disclaimer")
        },
        {
          path: "/contactus",
          name: "ContactLayout",
          component: () => import(/* webpackChunkName: "contactus" */ "@/layouts/Contact")
        }
      ]
    }
  ]
});

let state = {
  drawToolActivate: false,
  drawType: null,
  buildingLayer: null,
  drawLayer: null,
  clearState: 0
};
const mutations = {
  set_draw_tool_activate: (state, drawToolActivate) => {
    state.drawToolActivate = drawToolActivate;
  },
  set_draw_type: (state, drawType) => {
    state.drawType = drawType;
  },
  set_building_layer: (state, buildingLayer) => {
    state.buildingLayer = buildingLayer;
  },
  set_draw_layer: (state, drawLayer) => {
    state.drawLayer = drawLayer;
  },
  set_clear_state: state => {
    state.clearState += 1;
  }
};
const actions = {};
const getters = {
  drawToolActivate: state => {
    return state.drawToolActivate;
  },
  drawType: state => {
    return state.drawType;
  },
  buildingLayer: state => {
    return state.buildingLayer;
  },
  drawLayer: state => {
    return state.drawLayer;
  },
  clearState: state => {
    return state.clearState;
  },
  rectangleConstant: () => {
    return "Rectangle";
  },
  polygonConstant: () => {
    return "Polygon";
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

import Axios from "axios";
import "normalize.css";

import "@/assets/styles/index.less";

import elementUI from "./element";

const custom = {
  install: Vue => {
    Vue.use(elementUI);
    Vue.prototype.axios = Axios;
  }
};

export default custom;

<template>
  <div class="main-layout">
    <div class="system__header">
      <div class="system__title" tabindex="-1" @keyup.enter="() => window.location.reload()">
        <img :src="$store.getters['localize/content'].systemLogo" alt="system-logo" />
        <div>
          <p>{{ $store.getters["localize/content"].systemTitle }}</p>
          <p class="system__title--subTitle">{{ $store.getters["localize/content"].subTitle }}</p>
        </div>
      </div>

      <div class="system__menus big">
        <span
          title="HK RE Net"
          tabindex="1"
          style="margin-right: 15px"
          @keyup.enter.stop="openExternalLink"
          @click.stop="openExternalLink"
        >
          <img width="24" :src="require('@/assets/images/reNet.png')" alt="HK RE Net" />
        </span>

        <span
          :index="menu.index"
          v-for="menu in content.systemMenus"
          :key="'menu_' + menu.index"
          :title="menu.name"
          tabindex="2"
          @keyup.enter="() => handleSelect(menu.index)"
          :class="{ active: activeMenuIndex === menu.index }"
          @click.stop="() => handleSelect(menu.index)"
        >
          <i :class="`ali-icon-${menu.icon}`"></i>
        </span>

        <span
          :index="menu.index"
          v-for="menu in languages"
          :key="'menu_' + menu.index"
          :class="{ active: activeMenuIndex === menu.index }"
          @keyup.enter="() => handleSelect(menu.index)"
          @click.stop="() => handleSelect(menu.index)"
          tabindex="2"
        >
          <i :class="`ali-icon-${menu.icon}`"></i>
        </span>

        <span
          :index="menu.index"
          v-for="menu in font"
          :key="'menu_' + menu.index"
          :class="{ active: activeMenuIndex === menu.index }"
          @keyup.enter="() => handleSelect(menu.index)"
          @click.stop="() => handleSelect(menu.index)"
          tabindex="2"
        >
          <i :class="`ali-icon-${menu.icon}`"></i>
        </span>
      </div>

      <div class="system__menus small">
        <el-button
          class="system__menus--ctrl"
          icon="el-icon-menu"
          type="text"
          circle
          @click="showSmallMenus = !showSmallMenus"
        ></el-button>
        <div class="system__menus--wrap" v-show="showSmallMenus">
          <el-menu
            class="system__menus--language"
            default-active="4"
            active-text-color="#FDB814"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item :index="menu.index" v-for="(menu, idx) in languages" :key="idx">
              <i :class="`ali-icon-${menu.icon}`"></i>
            </el-menu-item>
          </el-menu>

          <el-menu default-active="0" mode="vertical" @select="handleSelect">
            <el-menu-item :index="menu.index" v-for="(menu, idx) in content.systemMenus" :key="idx">
              <i :class="`ali-icon-${menu.icon}`"></i>
              <span class="menu-name">{{ menu.name }}</span>
            </el-menu-item>
          </el-menu>

          <div class="fucntions">
            <el-button type="primary" @click="handleSelect('7')">
              <i class="ali-icon-font-size"></i>
            </el-button>
            <el-button
              plain
              @click="showSmallMenus = !showSmallMenus"
              icon="el-icon-caret-right"
            ></el-button>
          </div>
        </div>
      </div>
    </div>
    <router-view class="system__body" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "MainLayout",
  computed: {
    ...mapGetters("localize", ["content"])
  },
  data() {
    const languages = Object.freeze([
      { index: "4", name: "english", icon: "yingwen" },
      { index: "5", name: "traditionalCN", icon: "fanti" },
      { index: "6", name: "simplifiedCN", icon: "jianti" }
    ]);
    const font = Object.freeze([{ index: "7", name: "Font", icon: "font-size" }]);

    return {
      activeMenuIndex: "1",
      font,
      languages,
      showSmallMenus: false
    };
  },
  methods: {
    ...mapMutations("app", ["SET_FONT_SIZE"]),
    handleSelect(index) {
      switch (index) {
        case "1": {
          if (this.$route.name !== "MapLayout") {
            this.$router.push({ name: "MapLayout" });
          }
          break;
        }
        case "2": {
          if (this.$route.name !== "ContactLayout") {
            this.$router.push({ name: "ContactLayout" });
          }
          break;
        }
        case "3": {
          if (this.$route.name !== "DisclaimerLayout") {
            this.$router.push({ name: "DisclaimerLayout" });
          }
          break;
        }
        case "4": {
          this.$store.dispatch("localize/set_localized_content", "ENG");
          document.title = this.$store.getters["localize/content"].systemTitle;
          break;
        }
        case "5": {
          this.$store.dispatch("localize/set_localized_content", "CN-TW");
          document.title = this.$store.getters["localize/content"].systemTitle;
          break;
        }
        case "6": {
          this.$store.dispatch("localize/set_localized_content", "CN-ZH");
          document.title = this.$store.getters["localize/content"].systemTitle;
          break;
        }
        case "7": {
          this.SET_FONT_SIZE();
          break;
        }
        default: {
          false;
        }
      }
      this.activeMenuIndex = index;
    },
    openExternalLink() {
      window.open(this.content.reNetLink, "_blank");
    }
  },
  async mounted() {}
};
</script>

<style lang="less">
.main-layout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .system__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 8px solid #fdb814;
    width: 100%;
    height: 60px;
    background-color: #ffffff;

    .system__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      cursor: pointer;

      div {
        padding: 15px;
        font-size: 1.2em;
        color: #303030;
        font-weight: bolder;
        word-spacing: 0;
        white-space: nowrap;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        p {
          margin: 0;
          padding: 0;
        }
        p.system__title--subTitle {
          font-size: 0.6em;
          font-weight: normal;
        }
      }

      img {
        margin-left: 15px;
        height: 1.6em;
        vertical-align: middle;
      }
    }

    .system__menus.big {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        color: #909090;
        box-sizing: border-box;
        cursor: pointer;
      }

      span.active,
      span:hover {
        color: #fdb814;
        transition: background 0.4s;
      }
    }

    .system__menus.small {
      display: none;
    }
  }

  .system__body {
    flex: 1;
  }
}

@media screen and (max-width: 1280px) {
  .main-layout {
    .system__header {
      height: 50px;

      .system__title {
        div {
          padding: 0 5px;
          font-size: 0.8em;
          color: #303030;
          p {
            margin: 0;
            padding: 0;
          }
          p.system__title--subTitle {
            font-size: 0.6em !important;
          }
        }

        span {
          padding: 0 5px;
          font-size: 0.8em;
          color: #303030;
        }

        img {
          margin-left: 5px;
          height: 1em;
        }
      }

      .system__menus.big {
        a {
          width: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .main-layout {
    .system__header {
      border-bottom: 0;

      .system__title {
        span {
          padding: 0 5px;
          font-size: 0.8em;
          color: #303030;
        }

        img {
          margin-left: 5px;
          height: 1em;
        }
      }

      .system__menus.big {
        display: none;
      }
      .system__menus.small {
        display: block;

        .system__menus--ctrl {
          margin-right: 15px;
        }
        .system__menus--wrap {
          overflow: auto;
          z-index: 2004;
          border-top: 0;
          border-right: 0;

          position: fixed;
          top: 0;
          right: 0;
          width: 180px;
          height: 100%;
          background-color: #ffffff;
          box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.15);

          .menu-name {
            margin-left: 15px;
          }
          .fucntions {
            padding: 15px;
            text-align: center;
            height: 36px;
            line-height: 36px;
            border-radius: 4px;
            color: #ffffff;
            font-size: 14px;

            .el-button {
              margin: 5px 0;
              box-sizing: border-box;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>

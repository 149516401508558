import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import config from "./modules/config";
import localize from "./modules/localize/index";
import solarLayer from "./modules/solarLayer";
import drawTool from "./modules/drawTool";
import basemap from "./modules/basemap";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    config,
    localize,
    solarLayer,
    drawTool,
    basemap
  }
});

export default store;

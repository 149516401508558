import contentENG from "./content/cneng";
import contentTC from "./content/cntc";
import contentSC from "./content/cnzh";

const localizedContent = { "CN-ZH": contentSC, "CN-TW": contentTC, ENG: contentENG };

let state = {
  language: "ENG",
  content: contentENG
};
const mutations = {
  set_language: (state, language) => {
    state.language = language;
  },
  set_content: (state, content) => {
    state.content = content;
  }
};
const actions = {
  set_localized_content(state, language) {
    state.commit("set_language", language);
    state.commit("set_content", localizedContent[language]);
  }
};
const getters = {
  content: state => {
    return state.content;
  },
  language: state => {
    return state.language;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

let state = {
  currentMap: "shp"
};
const mutations = {
  set_current_map: (state, currentMap) => {
    state.currentMap = currentMap;
  }
};
const actions = {};
const getters = {
  currentMap: state => {
    return state.currentMap;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

<template>
  <div id="app" :style="fontSize">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      validUserInfo: false,
      formLabelWidth: "120px",
      systemTitle: "",
      form: {
        name: "",
        pass: ""
      }
    };
  },
  computed: {
    ...mapGetters("app", ["fontSize"]),
    invalidUserInfo: function () {
      return !this.validUserInfo;
    }
  },
  watch: {
    validUserInfo: {
      handler(value) {
        if (value === false) {
          return;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$store.dispatch("localize/set_localized_content", "ENG");
    document.title = this.$store.getters["localize/content"].systemTitle;
    this.systemTitle = this.$store.getters["localize/content"].systemTitle;

    const localizedContentMsg = this.$store.getters["localize/content"].message;

    this.$confirm(
      localizedContentMsg.alertMessage.message,
      localizedContentMsg.alertMessage.confirmTitle,
      {
        customClass: "alert-message",
        confirmButtonText: localizedContentMsg.alertMessage.confirmButtonText,
        cancelButtonText: localizedContentMsg.alertMessage.cancelButtonText,
        type: "warning",
        closeOnClickModal: false,
        iconClass: "n",
        dangerouslyUseHTMLString: true
      }
    )
      .then(() => {})
      .catch(() => {
        window.opener = null;
        window.open("about:blank", "_top").close();
      });
  },
  methods: {}
};
</script>

<style lang="less">
@import "assets/styles/element";

#app {
  width: 100%;
  height: 100vh;

  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;

  overflow: hidden;
}

.el-message-box.alert-message {
  width: 40%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;
  .el-message-box__content {
    .el-message-box__message {
      overflow: auto;
      p {
        line-height: 18px;
      }
    }
  }
}

.el-message-box__title {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;
}

@media screen and (max-width: 812px) {
  .el-message-box.alert-message {
    width: 100%;
    .el-message-box__content {
      padding: 0;
      p {
        line-height: 18px;
      }
    }
  }
  .el-message-box.messagebox-message {
    padding: 5px 0;
    width: 100%;
  }
}
// @media screen and (max-width: 375px) {
//   .el-message-box.alert-message {
//     width: 100%;
//   }
//   .el-message-box.messagebox-message {
//     width: 100%;
//   }
// }
</style>
